<div *ngIf="name == 'table'">
  <div class="mcard p-0" *ngIf="items?.length >0">
    <app-skeleton-element width="100%" height="45px" radius="10px 10px 0pc 0px" background="#e0e0e033">
    </app-skeleton-element>
    <div class="content table-responsive">
      <table class="fw">
        <tr *ngFor="let item of items">
          <td *ngFor="let column of allColumns">
            <app-skeleton-element width="100px" height="15px" radius="5px" background="#e0e0e033">
            </app-skeleton-element>
          </td>
          <td *ngIf="showLast != 'no'">
            <div class="flex-align flex-center">
              <!-- <app-skeleton-element width="40px" height="40px" radius="5px" background="#e0e0e033" class="mr-3">
              </app-skeleton-element> -->
              <app-skeleton-element width="40px" height="40px" radius="5px" background="#e0e0e033" class="mr-3">
              </app-skeleton-element>
              <app-skeleton-element width="40px" height="40px" radius="5px" background="#e0e0e033">
              </app-skeleton-element>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
<div *ngIf="name == 'orderdetails'">
  <div>
    <app-skeleton-element width="100%" height="120px" radius="5px" background="#e0e0e033">
    </app-skeleton-element>
  </div>
  <div class="mt-3">
    <div *ngFor="let item of loop">
      <div class="single-item">
        <div class="row">
          <div class="col-md-3">
            <app-skeleton-element width="100%" height="80px" radius="5px" background="#e0e0e033">
            </app-skeleton-element>
          </div>
          <div class="col-md-6">
            <div>
              <app-skeleton-element width="70%" height="30px" radius="5px" background="#e0e0e033">
              </app-skeleton-element>
            </div>
            <div class="mt-2">
              <app-skeleton-element width="60%" height="20px" radius="5px" background="#e0e0e033">
              </app-skeleton-element>
            </div>
            <div class="mt-2 flex-align">
              <app-skeleton-element width="40px" height="20px" radius="5px" background="#e0e0e033">
              </app-skeleton-element>
              <div class="ml-2">
                <app-skeleton-element width="40px" height="20px" radius="5px" background="#e0e0e033">
                </app-skeleton-element>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="flex-right flex-column flex-between">
              <div>
                <app-skeleton-element width="100%" height="30px" radius="5px" background="#e0e0e033">
                </app-skeleton-element>
              </div>
              <div class="mt-3">
                <app-skeleton-element width="50px" height="20px" radius="5px" background="#e0e0e033">
                </app-skeleton-element>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-3">
    <div>
      <app-skeleton-element width="50%" height="30px" radius="5px" background="#e0e0e033">
      </app-skeleton-element>
    </div>
    <div class="mt-2">
      <app-skeleton-element width="60%" height="20px" radius="5px" background="#e0e0e033">
      </app-skeleton-element>
    </div>
    <div class="mt-2">
      <app-skeleton-element width="40%" height="15px" radius="5px" background="#e0e0e033">
      </app-skeleton-element>
    </div>
  </div>
</div>
<div *ngIf="name == 'order'">
  <div class="mcard p-0">
    <div *ngFor="let order of loop" class="mb-2">
      <app-skeleton-element width="100%" height="130px" radius="0px" background="#e0e0e033">
        <div class="fw">
          <div class="flex-justify-align fw fh">
            <div>
              <app-skeleton-element width="100px" height="20px" radius="5px" background="#e0e0e033">
              </app-skeleton-element>
              <div class="mt-2">
                <app-skeleton-element width="80px" height="20px" radius="5px" background="#e0e0e033">
                </app-skeleton-element>
              </div>
            </div>
            <div class="flex-align">
              <app-skeleton-element width="50px" height="20px" radius="5px" background="#e0e0e033">
              </app-skeleton-element>
            </div>
          </div>
          <div class="flex-justify-align fw mt-3">
            <div>
              <app-skeleton-element width="120px" height="20px" radius="5px" background="#e0e0e033">
              </app-skeleton-element>

            </div>
            <div class="flex-align">
              <app-skeleton-element width="60px" height="20px" radius="5px" background="#e0e0e033">
              </app-skeleton-element>
            </div>
          </div>
        </div>

      </app-skeleton-element>
    </div>
  </div>
</div>
<div *ngIf="name == 'tablerow'">
  <div class="p-0" *ngIf="items?.length >0">
    <div class="table-responsive">
      <table class="fw">
        <tr *ngFor="let item of items">
          <td *ngFor="let column of allColumns">
            <app-skeleton-element width="100px" height="15px" radius="5px" background="#e0e0e033">
            </app-skeleton-element>
          </td>
          <td *ngIf="showLast != 'no'">
            <div class="flex-align flex-center">
              <!-- <app-skeleton-element width="40px" height="40px" radius="5px" background="#e0e0e033" class="mr-3">
              </app-skeleton-element> -->
              <app-skeleton-element width="40px" height="40px" radius="5px" background="#e0e0e033" class="mr-3 mb-3">
              </app-skeleton-element>
              <app-skeleton-element width="40px" height="40px" radius="5px" background="#e0e0e033">
              </app-skeleton-element>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
<div *ngIf="name == 'document-card'">
  <app-skeleton-element width="100%" height="180px" radius="10px" background="#e0e0e033">
  </app-skeleton-element>
</div>

<div *ngIf="name == 'count'" class="count-card">
  <app-skeleton-element width="100%" height="170px" radius="10px" background="#e0e0e033">
  </app-skeleton-element>
</div>

<div *ngIf="name == 'payment-graph'" class="fh">
  <app-skeleton-element width="100%" height="100%" radius="10px" background="#e0e0e033">
  </app-skeleton-element>
</div>

<div *ngIf="name == 'profile'">
  <div class="row nrow">
    <div class="col-md-7">
      <div class="mcard fh">
        <div class="flex-justify-align">
          <div>
            <app-skeleton-element width="130px" height="20px" radius="5px" background="#e0e0e033">
            </app-skeleton-element>
          </div>
          <div class="flex-align">
            <app-skeleton-element width="40px" height="40px" radius="5px" background="#e0e0e033">
            </app-skeleton-element>
            <app-skeleton-element width="40px" height="40px" radius="5px" background="#e0e0e033" class="ml-3">
            </app-skeleton-element>
          </div>
        </div>
        <div class="mt-4">
          <div class="row nrow">
            <div class="col-md-5 mt-4">
              <app-skeleton-element width="100%" height="20px" radius="5px" background="#e0e0e033">
              </app-skeleton-element>
            </div>
            <div class="col-md-5 mt-4">
              <app-skeleton-element width="100%" height="20px" radius="5px" background="#e0e0e033">
              </app-skeleton-element>
            </div>
            <div class="col-md-5 mt-4">
              <app-skeleton-element width="100%" height="20px" radius="5px" background="#e0e0e033">
              </app-skeleton-element>
            </div>
            <div class="col-md-5 mt-4">
              <app-skeleton-element width="100%" height="20px" radius="5px" background="#e0e0e033">
              </app-skeleton-element>
            </div>
            <div class="col-md-5 mt-4">
              <app-skeleton-element width="100%" height="20px" radius="5px" background="#e0e0e033">
              </app-skeleton-element>
            </div>
            <div class="col-md-5 mt-4">
              <app-skeleton-element width="100%" height="20px" radius="5px" background="#e0e0e033">
              </app-skeleton-element>
            </div>
            <div class="col-md-8 mt-4">
              <app-skeleton-element width="100%" height="20px" radius="5px" background="#e0e0e033">
              </app-skeleton-element>
            </div>

          </div>
          <div class="row nrow">
            <div class="col-md-3 mt-4">
              <app-skeleton-element width="100%" height="20px" radius="5px" background="#e0e0e033">
              </app-skeleton-element>
            </div>
            <div class="col-md-3 mt-4">
              <app-skeleton-element width="100%" height="20px" radius="5px" background="#e0e0e033">
              </app-skeleton-element>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-5">
      <div class="mcard fh">
        <div class="flex-justify-align">
          <div>
            <app-skeleton-element width="130px" height="20px" radius="5px" background="#e0e0e033">
            </app-skeleton-element>
          </div>
          <div>
            <app-skeleton-element width="40px" height="40px" radius="5px" background="#e0e0e033">
            </app-skeleton-element>
          </div>
        </div>
        <div class="mt-4">
          <div class="col-md-5 p-0">
            <app-skeleton-element width="100%" height="180px" radius="10px" background="#e0e0e033">
            </app-skeleton-element>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="name == 'box'">
  <app-skeleton-element width="100%" height="250px" radius="10px" background="#e0e0e033">
  </app-skeleton-element>
</div>
