import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-action-icon',
  templateUrl: './action-icon.component.html',
  styleUrls: ['./action-icon.component.scss']
})
export class ActionIconComponent implements OnInit {
  @Input() background;
  @Input() color;
  @Input() name;
  @Input() width;
  @Output() action = new EventEmitter()
  constructor() { }

  ngOnInit() {
  }

  clckEvent() {
    this.action.emit();
  }

}
