import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-skeleton-element',
  templateUrl: './skeleton-element.component.html',
  styleUrls: ['./skeleton-element.component.scss']
})
export class SkeletonElementComponent implements OnInit {
  @Input() width;
  @Input() height;
  @Input() background;
  @Input() radius;
  @Input() justify;
  constructor() { }

  ngOnInit(): void {
  }

}
