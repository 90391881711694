import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss']
})
export class SkeletonComponent implements OnInit {
  @Input() name;
  @Input() columns;
  @Input() rows;
  @Input() showLast;
  allColumns = [];
  items = [];
  loop = ['', '', '', '']
  constructor() { }

  ngOnInit(): void {
    let rows = this.rows ? this.rows : 8;
    let columns = this.columns ? this.columns : 5;
    if (this.name == 'table' || this.name == 'tablerow') {
      for (let i = 0; i < columns; i++) {
        this.allColumns.push({});
      }
      for (let j = 0; j < rows; j++) {
        this.items.push({});
      }
    }
  }


}
