<div class="upload-image">
  <div *ngIf="!loading" class="fh">
    <div *ngIf="!src" class="file-drop-div">
      <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" accept=".png,.jpg,.jpeg,.webp"
        class="file-drop">
        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
          <div (click)="openFileSelector()" class="drop-text">
            <span class="material-icons light">
              cloud_upload
            </span>
            <p>Drag and drop your <span *ngIf="bulk">multiple images</span><span *ngIf="!bulk">image</span> here or
              click here</p>
          </div>
        </ng-template>
      </ngx-file-drop>
    </div>
    <div *ngIf="src" class="preview cursor" (click)="preview()">
      <img [src]="src" />
      <div class="overlay">
        <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" accept=".png,.jpg,.jpeg,.webp"
          class="file-drop btn-drop">
          <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
            <i class="material-icons" (click)="openFileSelector()">
              cloud_upload
            </i>
          </ng-template>
        </ngx-file-drop>
      </div>
      <i class="material-icons delete-icon" (click)="deleteImage()">
        delete_forever
      </i>
    </div>
  </div>
  <div *ngIf="loading" class="loader-outer">
    <mat-spinner diameter="25" class="black-loader"></mat-spinner>
  </div>
</div>
