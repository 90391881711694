import { Pipe, PipeTransform } from '@angular/core';
import { orderColors } from '../../common';

@Pipe({
  name: 'color'
})
export class ColorPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let colors = orderColors;
    let index = value % colors.length;
    return colors[index];
  }

}
