import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeUnderScore'
})
export class removeUnderScorePipe implements PipeTransform {
  transform(value: string): string {

    
    const transformedValue = value.toLowerCase().split('_').map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }).join(' ');

      return transformedValue;

  }
}
