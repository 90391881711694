import { Pipe, PipeTransform } from '@angular/core';
import { productTypes } from '../../constant';

@Pipe({
  name: 'productType'
})
export class ProductTypePipe implements PipeTransform {

  transform(value: any, args): unknown {
    if (value) {
      let idKey = productTypes.find(type => {
        return type.key == value.type;
      })
      if (idKey) {
        return value[idKey.id].name;
      }
    }
    return null;
  }

}
