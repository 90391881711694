import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss']
})
export class UploadImageComponent implements OnInit {
  @Input() loading;
  @Input() src;
  @Input() bulk;
  @Output() upload = new EventEmitter();
  @Output() delete = new EventEmitter();
  constructor(
  ) { }

  ngOnInit() {
  }

  dropped(files: NgxFileDropEntry[]) {
    let formatedFile = [];
    for (const [i, droppedFile] of files.entries()) {

      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          // Here you can access the real file
          formatedFile.push(file);
          if (this.bulk) {
            if (i == (files.length - 1)) {
              this.upload.emit(formatedFile);
            }
          }
          else {
            this.upload.emit({ image: file });
          }
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }

  }

  preview() {

  }

  deleteImage() {
    this.delete.emit();
  }


}
