<div>
  <h5>Logout</h5>
  <div class="mt-2">
    <p class="light">Sure you want to proceed?</p>
  </div>
  <div class="dialog-action flex-right">
    <button class="btn primary-btn white-btn" (click)="close()">No</button>
    <button class="btn primary-btn blue-btn" (click)="submit()">
      <span>Yes</span>
      <!-- <mat-spinner *ngIf="loading" diameter="25"></mat-spinner> -->
    </button>
  </div>
</div>
