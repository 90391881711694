import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SafePipe } from '../pipes/safe/safe.pipe';
import { CapitalPipe } from '../pipes/capital/capital.pipe';
import { AddressPipe } from '../pipes/address/address.pipe';
import { SvgComponent } from 'src/app/dashboard/components/svg/svg.component';
import { ActionIconComponent } from 'src/app/dashboard/components/action-icon/action-icon.component';
import { UploadImageComponent } from 'src/app/dashboard/components/upload-image/upload-image.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { LogoutComponent } from 'src/app/dashboard/components/logout/logout.component';
import { ColorPipe } from '../pipes/color/color.pipe';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PrimaryPipe } from '../primary/primary.pipe';
import { SkeletonComponent } from 'src/app/dashboard/components/skeletons/skeleton/skeleton.component';
import { SkeletonElementComponent } from 'src/app/dashboard/components/skeletons/skeleton-element/skeleton-element.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { CountCardComponent } from 'src/app/dashboard/components/count-card/count-card.component';
import { ChartModule } from 'angular-highcharts';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ProductTypePipe } from '../pipes/productType/product-type.pipe';
import { removeUnderScorePipe } from '../pipes/removeunderscore';


@NgModule({
  declarations: [
    SafePipe,
    CapitalPipe,
    AddressPipe,
    SvgComponent,
    ActionIconComponent,
    UploadImageComponent,
    LogoutComponent,
    ColorPipe,
    PrimaryPipe,
    SkeletonComponent,
    SkeletonElementComponent,
    CountCardComponent,
    removeUnderScorePipe,
    ProductTypePipe
  ],
  imports: [
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    MatSidenavModule,
    MatListModule,
    MatDialogModule,
    MatMenuModule,
    MatSelectModule,
    MatDatepickerModule,
    MatGridListModule,
    MatSliderModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatTabsModule,
    MatChipsModule,
    MatSlideToggleModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatTreeModule,
    NgxFileDropModule,
    ScrollingModule,
    ColorPickerModule,
    ChartModule,
    NgxMatSelectSearchModule
  ],
  exports: [
    MatInputModule,
    MatFormFieldModule,
    MatSidenavModule,
    MatListModule,
    MatDialogModule,
    MatMenuModule,
    MatSelectModule,
    MatDatepickerModule,
    MatGridListModule,
    MatSliderModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    MatRadioModule,
    MatTabsModule,
    MatChipsModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    SafePipe,
    CapitalPipe,
    AddressPipe,
    MatStepperModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatTreeModule,
    SvgComponent,
    ActionIconComponent,
    UploadImageComponent,
    NgxFileDropModule,
    LogoutComponent,
    ColorPipe,
    ScrollingModule,
    PrimaryPipe,
    SkeletonComponent,
    SkeletonElementComponent,
    ColorPickerModule,
    CountCardComponent,
    ChartModule,
    NgxMatSelectSearchModule,
    ProductTypePipe,
    removeUnderScorePipe
  ],
  entryComponents: [
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class SharedModule { }
