import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../services/common/common.service';
import { SnackbarService } from '../services/snackbar/snackbar.service';


@Injectable({
  providedIn: 'root'
})

export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    // private msg: SnackbarService,
    // public common: CommonService,
    private router: Router,
  ) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = localStorage.getItem('token');
    request = request.clone({
      setHeaders: {
        'Authorization': token ? token : '',
      }
    });

    return next.handle(request)
      .pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {

          }
          return event;
        }),
        catchError((error: HttpErrorResponse) => {
          console.log(error)
          // if ((error.status == 403 && error['error'] == 'User has been blocked')) {
          // this.sendToLogin();
          // return this.handle403Error(request, next);
          // }
          if ((error.status == 401)) {
            localStorage.clear();
            this.router.navigate(['/auth/login'])
          }
          return throwError(error);
        })
      )
  }

  sendToLogin() {
    localStorage.clear();
    this.router.navigate(['/auth/login'], { queryParams: { blocked: true } });
  }

  // private handle403Error(request: HttpRequest<any>, next: HttpHandler) {
  //   let rToken = localStorage.getItem('rT');
  //   return this.common.refreshToken({ rT: rToken })
  //     .pipe(
  //       switchMap((res: any) => {
  //         localStorage.setItem('jT', res['data']['jT']);
  //         request = request.clone({
  //           setHeaders: {
  //             'Authorization': 'Bearer ' + res['data']['jT'],
  //           }
  //         });
  //         return next.handle(request);
  //       }));
  // }

}
