import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-count-card',
  templateUrl: './count-card.component.html',
  styleUrls: ['./count-card.component.scss']
})
export class CountCardComponent implements OnInit {
  @Input() count;
  @Input() title;
  @Input() background;
  @Input() backgroundImage;
  @Input() route;
  @Output() action = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  navigate() {
    this.action.emit(this.route)
  }

}
