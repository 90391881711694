export const productTypes = [
    { key: 'Category', id: 'categoryId' },
    { key: 'SubCategory', id: 'subCategoryId' },
    { key: 'Product', id: 'productId' },
    { key: 'ParentProduct', id: 'parentProductId' }
]

export const productTypesNew = [
    { key: 'Category', id: 'categoryId' },
    { key: 'Product', id: 'productId' },
]