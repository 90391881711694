import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (value) {
      let val = `${value.line1}${value.line2 ? ', ' : ''}${value.line2 ? value.line2 : ''}, ${value.city}, ${value.state}, ${value.pincode ? value.pincode : ''}`
      return val;
    }
    return null;
  }

}
